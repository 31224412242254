import { t } from 'i18next';
import React from 'react'
import './member.css'

const Member = (params) => {
  const {name, surname, roles} = params.data;
  const imagePath = '/assets/images/members/';
  const imageName = `${name}-${surname.slice(0,3)}`;
  return (
    <div className='member_card'>
      { params.displayImage &&  <img
           src={`${imagePath}${imageName}.jpg`}
           srcSet={`${imagePath}min/${imageName}@250.jpg 250w, ${imagePath}min/${imageName}@500.jpg 500w, ${imagePath}min/${imageName}@750.jpg 750w, ${imagePath}${imageName}.jpg 1000w`}
           sizes="(min-width: 535px) 45vw, (min-width: 768px) 35vw, (min-width: 1280px) 21vw, 18vw"
           alt={t("section.team.missingImage",{who:name})}
         />
      }
      <div className='member_card_name notranslate'>
        {name+" "+surname}
      </div>
      <div className='member_card_roles'>
        {roles.map((v,i)=>{return (v[0] !== "*" ? ((i)?", ":"") + t("section.team.roles."+v) : v.replace("*"," ")) })}
      </div>
    </div>
  )
}


export default Member