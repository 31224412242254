import React from 'react'
import './partners.css'
import { t } from 'i18next'
import { Divider, SponsorLink, SponsorWide } from '../../elements'
import { useJsonData } from '../../../utils/JsonDataContext'

const Partners = () => {
  const { partners } = useJsonData();

  return (
    <div className="section">
      <Divider sectionName="partners"/>
      <div className='section_title'> {t('section.partners.title')} </div>
      <div className='section_body'>
        <div className='sponsors_link container_small'>
          {partners.sponsorsLink.map((v,i)=>{
            return <SponsorLink data={v} key={i}/>
          })}
        </div>
        <div className='sponsors_wide container_small section_text'>
          {partners.sponsorsWide.map((v,i)=>{
            return <SponsorWide data={v} key={i}/>
          })}
        </div>
      </div>
    </div>
  )
}

export default Partners