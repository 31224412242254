import React from 'react'
import './header.css'
import { t } from 'i18next';
import { LangChanger, TimedMessage } from '../../elements';

function Header(){
  return (
    <div className="section">
      <div className='section_body section_header'>
        <LangChanger />
        <img src={`/assets/images/${t("section.header.logo")}.png`} alt='' />
        <TimedMessage/>
      </div>
    </div>
  )
}
export default Header