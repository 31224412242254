import React from 'react'
import './menuItem.css'
import { t } from 'i18next';

const MenuItem = (params) => {
  const [name, url] = (Array.isArray(params.data)) ? params.data : [params.data, "#"+params.data];
  return (
    <div>
      <a className='menu_item' href={url} onClick={params.menuToggler}>
        <img src={`/assets/images/menu/${name}.png`} alt=""/>
        <div>
          {t(`section.${name}.title`)}
        </div>
      </a>
    </div>
  )
}


export default MenuItem