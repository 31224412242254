import { t } from 'i18next';
import React from 'react'
import './directContactCard.css'

const DirectContactCard = (params) => {
  const {role, person, email, linkedin, phone} = params.data;
  return (
    <div className='direct_contact_card'>
      <div>{t("section.team.roles."+role)}</div>
      <div className='notranslate'>{person}</div>
      <a className='notranslate' href={"mailto:"+email}>{email}</a>
      <div className='notranslate'>{t("phonePL",{number:phone})}</div>
      { linkedin && <a href={linkedin} target="_blank" rel="noopener noreferrer">{linkedin.replace("https://www.","").slice(0,-1)}</a>}
    </div>
  )
}

export default DirectContactCard