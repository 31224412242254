import React from 'react'
import './sponsorLink.css'
import { t } from 'i18next';

const SponsorLink = (params) => {
  const {name, logo, url} = params.data;
  return (
    <a href={url} target="_blank" className='sponsorLink_card' rel="noopener noreferrer">
      { logo && <img src={`/assets/images/partners/${logo}.png`} alt={t("section.partners.sponsorsLink_name."+name,name)}/> }
    </a>
  )
}


export default SponsorLink;