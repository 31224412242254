import { t } from 'i18next';
import React from 'react'
import './memberText.css'

const MemberText = (params) => {
  return (
    <div className='member_text_card'>
      <div className='member_text_card_name'>{params.data.name + " " + params.data.surname}</div>
      <div className='member_text_card_roles'>
        {params.data.roles.map((v,i)=>{return (v[0] !== "*" ? ((i)?", ":"") + t("section.team.roles."+v) : v.replace("*"," ")) })}  
      </div>
    </div>
  )
}

export default MemberText