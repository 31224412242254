import React from 'react'
import './menuBtn.css'

const MenuBtn = (params) => {
  return (
    <div className='menu_button' onClick={params.menuToggler}>
      <div/><div/><div/><div/>
    </div>
  )
}


export default MenuBtn