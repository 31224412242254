import React from 'react'
import './footer.css'
import { useJsonData } from '../../../utils/JsonDataContext'
import { t } from 'i18next'

const Footer = () => {
  const { footer } = useJsonData();
  return (
    <footer>
      <p className='notranslate'>&copy; {new Date().getFullYear()} PŁ Raptors All Rights Reserved</p>
      <p>
        Made with ♡ by
        <a href="https://www.linkedin.com/in/dariuszwvwitkowski" target="_blank" rel="noopener noreferrer" className='notranslate'>Dariusz Witek vel Witkowski</a>
      </p>
      <p>
        {t("footer."+footer.text,footer.text)}
        <a href={footer.link.url} target="_blank" rel="noopener noreferrer" className='notranslate'>
          {t("footer."+footer.link.text,footer.link.text)}
        </a>
      </p>
    </footer>
  )
}

export default Footer