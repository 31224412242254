import React from 'react'
import './robots.css'
import { t } from 'i18next'
import { useJsonData } from '../../../utils/JsonDataContext';
import { Divider } from '../../elements'

const Robots = () => {
  const { robots } = useJsonData(),
        mid_rover = Math.ceil(robots.rover.length/2),
        mid_drone = Math.ceil(robots.drone.length/2);

  const RoboInfoCard = (params)=>{
    const {param, val, val_prefix, subNote} = params.data;
    return (
      <div className='roboInfoCard'>
        <div className='rIC_body'>
          <div className='rIC_header'>
            <div class="rIC_header_name"> {t('section.robots.params.'+param,param)} </div>
            { subNote &&
              <div class="rIC_header_subNote">
                ?
                <div class="rIC_header_subNote_text">{t('section.robots.subNotes.'+subNote,subNote)}</div>
              </div>
            }
          </div>
          <div className='rIC_value'>
            { val_prefix && t('section.robots.prefixes.'+val_prefix,val_prefix)+" " }
            { t('section.robots.values.'+val,val) }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="section">
      <Divider sectionName="robots"/>
      <div className='section_title'> {t('section.robots.title')} </div>
      <div className='section_body'>
        <div className='container_big'>
          <div className='robots_tab'>
            <span className='robots_tab_rover' text={t('section.robots.subtitles.rover')}/>
            <div class='roboInfo_group roboInfo_left'>
              {
                robots.rover.slice(0,mid_rover).map((v,i)=>{
                  return <RoboInfoCard data={v} key={i}/>
                })
              }
            </div>
            <div class='roboInfo_group roboInfo_right'>
              {
                robots.rover.slice(mid_rover).map((v,i)=>{
                  return <RoboInfoCard data={v} key={i}/>
                })
              }
            </div>
          </div>
          <div className='robots_tab'>
            <span className='robots_tab_drone' text={t('section.robots.subtitles.drone')}/>
            <div class='roboInfo_group roboInfo_left'>
              {
                robots.drone.slice(0,mid_drone).map((v,i)=>{
                  return <RoboInfoCard data={v} key={i}/>
                })
              }
            </div>
            <div class='roboInfo_group roboInfo_right'>
              {
                robots.drone.slice(mid_drone).map((v,i)=>{
                  return <RoboInfoCard data={v} key={i}/>
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Robots