import React, { useEffect } from 'react'
import './divider.css'
import { useInView } from 'react-intersection-observer';
import { useDocumentRoot } from '../../../utils/DocumentRootContext';


const Divider = (params) => {
  const urlPath = "/assets/images/dividers/";
  const { mainBg } = useDocumentRoot();

  const { ref } =  useInView({
    onChange: (inView) => {
      let elem = document.getElementById("bg-"+params.sectionName);
      if(elem){
        if (inView) {
          elem.classList.add("show");
        } else {
          elem.classList.remove("show");
        }
      }
    }
  });

  useEffect(()=>{
    if(mainBg.current){
      let bg = document.createElement("img");
          bg.src = `${urlPath}bg-${params.sectionName}.png`;
          bg.id = "bg-"+params.sectionName;
          bg.alt = "";
      mainBg.current.prepend(bg)
    }
  },[mainBg]);

  return (
    <div id={params.sectionName} className='section_divider centered-no-repeat' style={{backgroundImage:`url(${urlPath}title-${params.sectionName}.png)`}}>
      <div ref={ref} />
    </div>
  )
}

export default Divider