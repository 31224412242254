import { t } from 'i18next';
import React from 'react'
import './sponsorWide.css'

const SponsorWide = (params) => {
  const {logo, name, text, url} = params.data;

  const getLogos = ()=>{
    if(logo){
      return (
        <>
          <div className='sponsorWide_logo'><img src={`/assets/images/partners/${logo}.png`} alt={name} /></div>
          <div className='sponsorWide_logo_vertical'><img src={`/assets/images/partners/${logo}-vertical.png`} alt={name} /></div>
        </>
      )
    }
    return;
  }

  return (
    <div className='sponsorWide_card'>
      <div className='sponsorWide_text'>
        {
          text.map((v,i)=>{
            return <p key={i}>{t("section.partners.sponsorsWide_text."+v,v)}</p>
          })
        }
      </div>
      { (url)?
          <a href={url} target="_blank" className='sponsorWide_link' rel="noopener noreferrer"> { getLogos() } </a>
        :
          getLogos()
      }
    </div>
  )
}


export default SponsorWide;