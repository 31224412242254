import React, {useRef, useEffect, useState } from 'react'
import './App.css'
import { MenuBtn, TimedMessage } from './elements'
import { Menu, Header, Achievements, Contact, Footer, Gallery, Partners, Robots, Team } from './tabs'
import { useTranslation } from 'react-i18next'
import { DocumentRootContext } from '../utils/DocumentRootContext'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useLocation } from "react-router-dom";
import i18next from 'i18next';

function App(params) {
  const { t, i18n } = useTranslation();
  const menuToggler = () => { appRef.current.classList.toggle("menu_active")};
  const appRef = useRef();
  const mainBgRef = useRef();
  const { pathname, hash, key } = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);

  if (!isLoaded) {
    if(params.pageLang){
      i18next.changeLanguage(params.pageLang);
    }
    setIsLoaded(true);
  }

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  },[i18n.language]);

  useEffect(() => {
    document.querySelector('link[rel="canonical"]').setAttribute("href", "https://raptors.p.lodz.pl/");
  });

  return (
    <div className="App" ref={appRef}>
      <div className='mainPageBG centered-no-repeat' ref={mainBgRef} />
      <MenuBtn menuToggler={menuToggler} />
      <Menu menuToggler={menuToggler} />
      <DocumentRootContext.Provider value = { {mainBg:mainBgRef} }>
        <Scrollbars universal style={{height:"100vh"}} className='main_page scrollbar_color'
          renderTrackHorizontal={({ style, ...props }) => <div {...props} style={{ display: 'none' }}/>}
        >
          <Header/>
          <Team/>
          <Robots/>
          <Achievements/>
          <Gallery/>
          <Partners/>
          <Contact/>
          <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8188.721681837918!2d19.453607727345215!3d51.75255840760393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6a891a3c58b063b4!2sWydzia%C5%82+Elektrotechniki%2C+Elektroniki%2C+Informatyki+i+Automatyki+Politechniki+%C5%81%C3%B3dzkiej!5e0!3m2!1spl!2sus!4v1540008366553&center=51.7524467,19.4535714&zoom=15&maptype=roadmap"/>
          <Footer/>
        </Scrollbars>
      </DocumentRootContext.Provider>

    </div>
  );
}

export default App;