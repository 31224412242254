import React, { useState } from 'react'
import './team.css'
import { Divider, Member, MemberText } from '../../elements'
import { t } from 'i18next'
import { useJsonData } from '../../../utils/JsonDataContext'

const Team = () => {
  const tabs = ["active","supporting","former"];
  const { members } = useJsonData();
  const [ active, setActive ] = useState(0);
  const [ lastActive, setLastActive ] = useState(1);
  const [ timestamp, setTimestamp ] = useState(0);
  const [ animation, setAnimation ] = useState(false);

  const switchTab = (tab)=>{
    let ts = new Date().getTime();
    if(tab !== active && timestamp <= ts - 600){
      setAnimation(true);
      setTimestamp(ts)
      setLastActive(active);
      setActive(tab)
    }
  }

  return (
    <div className="section">
      <Divider sectionName="team"/>
      <div className='section_title'>
        {t('section.team.title')}
      </div>
      <div className={'section_body show_'+tabs[active] + " anim_"+((active-lastActive>0)?"right":"left") + ((animation)?"":" anim_hide")}>
        <div className='section_text container_small'>
          {t('section.team.description')}
        </div>
        <div className='membersToggle'>
          <button onClick={()=>{switchTab(0)}}>{t( "section.team.currentTeam" )}</button>
          <button onClick={()=>{switchTab(1)}}>{t( "section.team.supportingTeam")}</button>
          <button onClick={()=>{switchTab(2)}}>{t( "section.team.formerTeam")}</button>
        </div>
        <div className='section_team_members container_big'>
          <div className='members_display' key={active}>
            { (active === 2) && <img className="members_display_team" src='/assets/images/members/group_historical.png' alt={t("section.team.missingImage_group")} loading="lazy"/> }
            {
              members[tabs[active]].map((v,i)=>{
                if(active === 2){
                  return <MemberText data={v} key={i}/>
                } else {
                  return <Member data={v} displayImage={true} key={i}/>
                }
              })
            }
          </div>
          { lastActive !== null && <div className='members_display_last' key={lastActive+"l"}>
            { (lastActive === 2) && <img className="members_display_team" src='/assets/images/members/group_historical.png' alt={t("section.team.missingImage_group")}/> }
              {
                members[tabs[lastActive]].map((v,i)=>{
                  if(lastActive === 2){
                    return <MemberText data={v} key={i}/>
                  } else {
                    return <Member data={v} displayImage={animation} key={i}/>
                  }
                })
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Team