import React from 'react'
import './galleryCard.css'
import { t } from 'i18next';

const GalleryCard = (params) => {
  const {event, year, text} = params.data;
  const imageName = `/assets/images/gallery/${event}-${year}/thumbnails/preview`;
  return (
    <a href={`/gallery/${event}-${year}`} target="_blank" rel="noopener noreferrer" className='galleryCard_link'>
      <img className='galleryCard_preview'
            src={`${imageName}.jpg`}
            srcSet={`${imageName}@200.jpg 200w, ${imageName}@400.jpg 400w, ${imageName}.jpg 600w`}
            sizes="(max-width: 635px) 56vw, (max-width: 1000px) 30vw, (max-width: 1600px) 21vw, 15vw"
            alt=""/>
      <div className='galleryCard_text notranslate'>{t("section.gallery.cardText."+text,text)}</div>
    </a>
  )
}

export default GalleryCard;