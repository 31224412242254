import React, { useEffect } from 'react'
import './notFound.css'
import { useTranslation } from 'react-i18next'

function NotFound() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  },[i18n.language]);

  return (
    <div className="error404">
      <div>
        <div className='message'>
          <h2>{t("pageNotFound.0")}</h2>
          <p>{t("pageNotFound.1")}</p>
          <p>{t("pageNotFound.2")}<a href="/">{t("pageNotFound.3")}</a></p>
        </div>
      </div>
      <div>
        <img src='/assets/images/raptor.png' alt='Raptor' />
      </div>
    </div>
  );
}
export default NotFound;