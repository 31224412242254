import React from 'react'
import './thumbnailMenu.css'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { Footer } from '../../tabs'

const ThumbnailMenu = (params) => {
  const {event, year, text, photos, format} = params.data;
  const callback = params.callback;
  const photosCnt = parseInt(photos);
  const GalleryThumbnail = (params) => {
    return (
      <div className="gallery_thumbnail" onClick={()=>{callback(false, params.imgID-1)}}>
        <img src={`/assets/images/gallery/${event}-${year}/thumbnails/${params.imgID}.jpg`}/>
      </div>
    )
  }

  return (
    <div className='gallery_thumbnail_tab'>
      <Scrollbars universal style={{height:"100%", textAlign:"center"}} className='scrollbar_color'
        renderTrackHorizontal={({ style, ...props }) => <div {...props} style={{ display: 'none' }}/>}
      >
        <div className='gallery_thumbnail_block'>
          {Array.from({length: photosCnt}, (_, i) => 
            <GalleryThumbnail imgID={i+1} key={`th_${i}`}/>
          )}
        </div>
      </Scrollbars>
      <div className="gallery_footer">
        <p className='notranslate'>&copy; {new Date().getFullYear()} <a href="/">PŁ Raptors</a> All Rights Reserved</p>
        <p> Made with ♡ by <a href="https://www.linkedin.com/in/dariuszwvwitkowski" target="_blank" rel="noopener noreferrer" className='notranslate'>Dariusz Witek vel Witkowski</a></p>
      </div>
    </div>
  )
}


export default ThumbnailMenu