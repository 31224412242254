import React from 'react'
import './menu.css'
import { MenuItem } from '../../elements'
import { useJsonData } from '../../../utils/JsonDataContext'

const Menu = (params) => {
  const { menu } = useJsonData();
  return (
    <nav>
      <div className='navbar_items'>
        {
          menu.sections.map((v,i)=>{
            return (
              <MenuItem data={v} menuToggler={ params.menuToggler } key={i}/>
            )
          })
        }
      </div>
    </nav>
  )
}

export default Menu