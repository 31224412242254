import React from 'react'
import './gallery.css'
import { Divider, GalleryCard } from '../../elements'
import { t } from 'i18next'
import { useJsonData } from '../../../utils/JsonDataContext'

const Gallery = (params) => {
  const { gallery } = useJsonData();

  return (
    <div className="section">
      { ! params.noDivider && <Divider sectionName="gallery"/> }
      <div className='section_title'> {t('section.gallery.title')} </div>
      <div className='section_body'>
        <div className='gallery_tab container_big'>
          {
            gallery.map((v,i)=>{
              return <GalleryCard data={v} key={i} />
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Gallery