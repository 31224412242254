import React from 'react'
import './langChanger.css'
import i18next from 'i18next'
import { useJsonData } from '../../../utils/JsonDataContext';

const LangChanger = () => {
  const { menu } = useJsonData();
  return (
    <div className='lang_changer notranslate'>
      {
        menu.languages.map((v,i)=>{
          return (
            <div 
              key = { i }
              onClick = { () => i18next.changeLanguage(v) }
              className = { i18next.language.indexOf(v) >= 0 ? "active" : "" }
            >
            {v}
            </div>
          )
        })
      }
    </div>
  )
}

export default LangChanger