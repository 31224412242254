import React from 'react'
import { useState, useCallback } from 'react';
import { useJsonData } from '../../../utils/JsonDataContext';
import { OpenInNewWindowIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'react-i18next'
import './timedMessage.css';


export const TimedMessage = () => {
  const { timedMessage } = useJsonData();
  const { t } = useTranslation();

  const displayMessage = () => {
    if(timedMessage.timeout){
        if(Date.parse(timedMessage.timeout) > Date.now()){
            if(timedMessage.link){
                return (
                    <div className='timedMessage'>
                        <div className='message'>
                            <a href={timedMessage.link}>
                                {t("donateUs")} <OpenInNewWindowIcon />
                            </a> 
                        </div>
                    </div>

                )
            }
            return (
                <div className='timedMessage'>
                    <div className='message'>
                        {t("donateUs")}
                    </div>
                </div>
            )
        }
    }
    return "";
  }

  return displayMessage();
};