import React, { useEffect } from 'react'
import './GalleryHub.css'
import { Gallery } from './tabs'
import { useTranslation } from 'react-i18next'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useLocation } from "react-router-dom"

function GalleryHub() {
  const { t, i18n } = useTranslation();
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    document.title = `Raptors - Gallery`;
    document.querySelector('link[rel="canonical"]').setAttribute("href", `${window.location.href}`);
  });

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  },[i18n.language]);

  return (
    <div className="galleryHub">
      <Scrollbars universal style={{height:"100vh"}} className='main_page scrollbar_color'>
        <Gallery noDivider={true} />
      </Scrollbars>
    </div>
  );
}

export default GalleryHub;