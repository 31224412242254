import { t } from 'i18next';
import React from 'react'
import './projectCard.css'

const ProjectCard = (params) => {
  const {name, funding, description, duration} = params.data;

  const getTime = ()=>{
    let [from, to] = duration;
    let time = `${t("months."+(from[0]-1))} ${from[1]} - `;
    if(to) time += `${t("months."+(to[0]-1))} ${to[1]}`;
    return time
  }

  return (
    <div className='project_card'>
      <div className='project_card_name notranslate'
           detail={t("section.projects.Project","Project")+":"}>
        <q>{t("section.projects.projectName."+name, name)}</q>
      </div>
      <div className='project_card_description'
           detail={t("section.projects.Description","Description")+":"}>
        {t("section.projects.projectDescription."+description, description)}
      </div>
      <div className='project_card_duration'
           detail={t("section.projects.Duration","Duration")+":"}>
        {getTime()}
      </div>
      <div className='project_card_financing'
           detail={t("section.projects.Financing","Financing")+":"}>
        {funding.map((v,i)=>{return <p key={i}>{t("section.projects.projectFunding."+v, v)}</p>})}
      </div>
    </div>
  )
}

export default ProjectCard;