import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { App, GalleryHub, GalleryViewer, NotFound } from './containers';
import { JsonDataContext } from './utils/JsonDataContext'
import './index.css';
import './i18n';

document.documentElement.lang = navigator.language;

fetch( "/assets/page.json", {cache: "no-cache"} ).then( resp => resp.json() ).then( data => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <JsonDataContext.Provider value={data}>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<App />} />
          {
            data.menu.languages.map((v,i)=>{
              return <Route path={v} element={<App pageLang={v} />} key={i}/>
            })
          }
          <Route path="/gallery">
            {
              data.gallery.map((v,i)=>{
                return ([
                  ((v.legacy)?<Route path={`gallery-${v.year}-${v.event}.html`} element={<Navigate {...{to: `/gallery/${v.event}-${v.year}`, replace: true}} />} key={"l"+i}/>:null),
                  <Route path={`${v.event}-${v.year}`} element={<GalleryViewer data={v} />} key={i}/>
                ])
              })
            }
            <Route index element={<GalleryHub />} />
          </Route>
          <Route  path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </JsonDataContext.Provider>
  );
});
