import React, { useState, useEffect } from 'react'
import './GalleryViewer.css'
import { useTranslation } from 'react-i18next'
import { ThumbnailMenu } from './elements';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function GalleryViewer(params) {
  const { t, i18n } = useTranslation();
  const [ photoNo, setPhotoNo ] = useState(0);
  const [ viewThumbnails, setViewThumbnails ] = useState(true);
  const {event, year, text, photos, format} = params.data;
  const photosCnt = parseInt(photos);

  const switchPhotos = (direction)=>{
    setPhotoNo(photoNo+direction);
  }

  const calcPhotoNo = ()=>{
    let imgNo = calcPhotoID();
    if (imgNo < 10 && photosCnt > 9) imgNo = "0"+imgNo;
    return imgNo;
  }

  const calcPhotoID = (z=0)=>{
    let imgID = (photoNo+z)%photosCnt;
    if (imgID < 0) imgID += photosCnt;
    return imgID+1;
  }

  const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
    <div className='zoomControls'>
      <div className='photo_button' onClick={() => zoomIn()}>
        <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(3 3)">
            <circle cx="5.5" cy="5.5" r="5"/><path d="m7.5 5.5h-4zm-2 2v-4z"/><path d="m14.5 14.5-5.367-5.367"/>
        </g></svg></div>
      <div className='photo_button' onClick={() => zoomOut()}>
        <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(3 3)">
            <circle cx="5.5" cy="5.5" r="5"/><path d="m7.5 5.5h-4z"/><path d="m14.571 14.5-5.45-5.381"/>
        </g></svg></div>
      <div className='photo_button' onClick={() => resetTransform()}>
        <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(3 1)">
            <path d="m.5 7.5c0 2.7614237 2.23857625 5 5 5 2.76142375 0 5-2.2385763 5-5 0-2.76142375-2.23857625-5-5-5-1.60217594 0-3.02834512.75357449-3.94340319 1.92561913"/><path d="m1.5.5v4h4"/><path d="m14.5 16.5-5.379-5.379"/>
        </g></svg></div>
    </div>
  );

  const PhotoDisp = (params)=>{
    let imgID = calcPhotoID(params.z);
    if (params.z != 0)
      return <img src={`/assets/images/gallery/${event}-${year}/${imgID}.${format}`} alt={`${imgID}`}/>;
    return (
      <TransformWrapper
        centerOnInit={true}
      >
        {(utils) => (<>
          <Controls {...utils} />
          <TransformComponent>
            <img src={`/assets/images/gallery/${event}-${year}/${imgID}.${format}`} alt={`${imgID}`} className="show"/>
          </TransformComponent>
        </>)}
      </TransformWrapper>
    );
  }

  const ThumbDisp = (params)=>{
    let imgID = calcPhotoID(params.z);
    if (params.z == 0)
      return <img src={`/assets/images/gallery/${event}-${year}/thumbnails/${imgID}.${format}`} alt={`${imgID}`} className="show"/>;
    return <img src={`/assets/images/gallery/${event}-${year}/thumbnails/${imgID}.${format}`} alt={`${imgID}`} onClick={()=>{switchPhotos(params.z)}}/>;
  }

  const switchTab = (state = true, photoID = 0)=>{
    if(!state){ setPhotoNo(photoID); }
    setViewThumbnails(state);
  }

  useEffect(() => {
    document.title = `Raptors - ${event.toUpperCase()} ${year}`;
    document.querySelector('meta[name="description"]').setAttribute("content", `Photo gallery during ${text}`);
    document.querySelector('link[rel="canonical"]').setAttribute("href", `${window.location.href}`);
  });

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  },[i18n.language]);

  return (
    <div className="gallery_view">
      <div className='gallery_title'>
        {t("section.gallery.cardText."+text,text)}
      </div>
      <div className={`gallery_body container_small ${((viewThumbnails)?"thumbnails":"pictures")}`}>
        <ThumbnailMenu data={params.data}  callback={switchTab} />
        <div className='gallery_photo_tab'>
          <div className='photo_area'>
            {Array.from({length: 5}, (_, i) => 
              <PhotoDisp z={i-2} suffix="" key={`p_${i}`}/>
            )}
            <div className='photo_info'>
              <div className='photo_arrow photo_arrow_left' onClick={()=>{switchPhotos(-1)}}></div>
              <div>{`${calcPhotoNo()} / ${photosCnt}`}</div>
              <div className='photo_arrow photo_arrow_right' onClick={()=>{switchPhotos(1)}}></div>
            </div>
          </div>
          <div className='photo_controll'>
            <div className='back_button' onClick={()=>{switchTab()}}><div/><div/><div/></div>
            <div className='photo_carousel'>
              {Array.from({length: 5}, (_, i) => 
                <ThumbDisp z={i-2} key={`t_${i}`}/>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GalleryViewer;