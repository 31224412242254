import React from 'react'
import './contact.css'
import { t } from 'i18next'
import { useJsonData } from '../../../utils/JsonDataContext';
import { Divider, DirectContactCard } from '../../elements';

const Contact = () => {
  const { contact } = useJsonData();
  const addressKeys = ["header","name","place","street","post"];

  return (
    <div className="section">
      <Divider sectionName="contact"/>
      <div className='section_title'> {t('section.contact.title')} </div>
      <div className='section_body'>
        <div className='contact_body'>
          <div className='contact_group contact_links'>
            {contact.links.map((v,i)=>{
              return (<a href={v.url} target="_blank" rel="noopener noreferrer" key={i}>
                  <img src={`/assets/images/socials/${v.logo}.png`} alt={v.url}/>
                </a>)
            })}
          </div>
          <div className='contact_group contact_email'>
            {contact.emails.map((v,i)=>{
              return <a href={"mailto:"+v} key={i}>{v}</a>
            })}
          </div>
          <div className='contact_group contact_direct'>
            {contact.direct.map((v,i)=>{
              return <DirectContactCard data={v} key={i}/>
            })}
          </div>
          <div className='contact_group contact_address'>
            {addressKeys.map((v,i)=>{
              return <div key={i} className='notranslate'>{t("section.contact.address."+v)}</div>
            })}
          </div>
        </div>
        <div className='section_text container_small'>
          {t("section.contact.statement")}
        </div>
      </div>
    </div>
  )
}

export default Contact