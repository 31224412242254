import React, { useState } from 'react'
import './achievements.css'
import { t } from 'i18next'
import { useJsonData } from '../../../utils/JsonDataContext'
import { Divider, EventCard, ProjectCard } from '../../elements'
import { Scrollbars } from 'react-custom-scrollbars-2'

const Achievements = () => {
  const { events, competitions, projects } = useJsonData();
  const years = Object.keys(events).reverse();
  const [ year, setYear ] = useState(
    () => {
      for(let y in years){
        if(events[years[y]].length)
          return years[y]
      }
    }
  );
  return (
    <div className="section">
      <Divider sectionName="achievements"/>
      <div className='section_title'> {t('section.achievements.title')} </div>
      <div className='section_body'>
        <div className='competitions_tab container_small'>
          {
            Object.keys(competitions).reverse().map((k,i)=>{
              return (
                <div className='competitions_list' key={i} year={k}>
                  {
                    competitions[k].map((v,j)=>{
                    return <EventCard data={v} key={j} />
                  })
                }
              </div>
              )
            })
          }
        </div>
      </div>
      <div id="events" className='section_divider centered-no-repeat' style={{backgroundImage:`url(/assets/images/dividers/title-events.png)`}}></div>
      <div className='section_title'> {t('section.events.title')} </div>
      <div className='section_body'>
        <div className='events_tab container_small'>
          <div className='events_year_select'>
            {
              years.map((k,i)=>{
                return(
                <div
                  key={i} 
                  onClick={(events[k].length)?()=>setYear(k):undefined}
                  className={(year===k?'active':'')+(events[k].length?'':' empty')}
                > {k} </div>
              )})
            }
          </div>
          <Scrollbars universal style={{height:(years.length*60+40)+"px"}} className='events_list scrollbar_color' key={year}
            renderTrackHorizontal={({ style, ...props }) => <div {...props} style={{ display: 'none' }}/>}
          >
            {
              events[year].map((v,i)=>{
                return <EventCard data={v} key={i} />
              })
            }
          </Scrollbars>
        </div>
      </div>
      <div id="projects" className='section_divider centered-no-repeat' style={{backgroundImage:`url(/assets/images/dividers/title-projects.png)`}}></div>
      <div className='section_title'> {t('section.projects.title')} </div>
      <div className='section_body'>
        <div className='projects_tab container_small'>
          <div className='projects_list'>
            {
              projects.map((v,i)=>{
                return <ProjectCard data={v} key={i} />
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Achievements