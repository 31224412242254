import { t } from 'i18next';
import React from 'react'
import './eventCard.css'

const EventCard = (params) => {
  const {name, place, location, duration, description} = params.data;

  const getLocation = ()=>{
    if(typeof location === 'object'){
      let object = (location.object)?t("section.events.eventObject."+location.object, location.object)+", ":"",
          city = (location.city)?t("section.events.eventCity."+location.city, location.city)+", ":"",
          country = (location.country)?t("section.events.eventCountry."+location.country):"";
      return object+city+country;
    }
    return location;
  }

  const getTime = ()=>{
    let [from, to] = duration;
    if(to){
      return `${from[0]} ${(from[1] !== to[1])?" "+ t("months."+(from[1]-1)):""} - ${to[0]} ${t("months."+(to[1]-1))}`
    }
    return `${from[0]} ${t("months."+(from[1]-1))}`
  }

  const nth = (n)=>{return["st","nd","rd"][((n+90)%100-10)%10-1]||"th"}
  const getPlace = ()=>{
    if(place){
      let text = t('section.achievements.place');
      return `${place}${(text == "place")?nth(place):""} ${text}`;
    }
    return "";
  }

  return (
    <div className='event_card'>
      <div className='event_card_time' date={getTime()} />
      <div className='event_card_name notranslate'>
        {t("section.events.eventName."+name, name)}
      </div>
      <div className='event_card_location notranslate'>
        {getLocation()}
      </div>
      <div className='event_card_place'>
        {getPlace()}
      </div>
      <div className='event_card_description'>
          {(description)?t("section.events.eventDescription."+description, description):""}
        </div>
    </div>
  )
}

export default EventCard;